<template>
  <v-card class="elevation-0">
    <v-card-title
      class="primary--text"
      style="height: 50px !important; background-color: white"
    >
      <span>Reference</span>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-timeline dense>
        <v-slide-x-reverse-transition group hide-on-leave>
          <v-timeline-item
            v-for="item in items"
            :key="item.id"
            :color="item.color"
            small
            class="mt-n6 mr-8"
          >
            <v-alert :value="true" :color="item.color" class="white--text mb-2">
              <v-row v-if="deviceType === 3">
                <v-col>
                  {{ item.classification }}
                </v-col>
                <v-col>
                  {{ item.systolicBloodPressure }}
                </v-col>
                <v-col>
                  {{ item.diastolicBloodPressure }}
                </v-col>
                <v-col> {{ item.case }} {{ item.pulseRate }} </v-col>
              </v-row>
              <v-row v-else>
                <v-col>
                  {{ item.description }}
                </v-col>
                <v-col>
                  {{ item.first_unit }}
                </v-col>
                <v-col>
                  {{ item.second_unit }}
                </v-col>
              </v-row>
            </v-alert>
          </v-timeline-item>
        </v-slide-x-reverse-transition>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>
<script>
// eslint-disable-next-line no-unused-vars
const COLORS = [
	'blue lighten-2',
	'green darken-1',
	'amber accent-2',
	'orange accent-4',
	'deep-orange accent-4',
	'blue lighten-3',
	'blue lighten-4',
];
const ICONS = {
	low: 'mdi-thermometer-low',
	normal: 'mdi-thermometer',
	advice: 'mdi-thermometer-plus',
	acceptable: 'mdi-thermometer-alert',
	urgent: 'mdi-thermometer-high',
};

export default {
	props: ['item'],
	computed: {
		deviceType () {
			return this.item.deviceType;
		},
		items () {
			var items = [];
			switch (this.item.deviceType) {
			case 1:
				items = [
					{
						id: 1,
						color: COLORS[0],
						icon: ICONS.low,
						description: 'Low',
						first_unit: '35 or less',
						second_unit: '95.0 or less',
					},
					{
						id: 2,
						color: COLORS[1],
						icon: ICONS.normal,
						description: 'Normal Readings',
						first_unit: '36.5 - 37.5',
						second_unit: '99.7 - 99.5',
					},
					{
						id: 3,
						color: COLORS[2],
						icon: ICONS.acceptable,
						description: 'Acceptable to continue home monitoring',
						first_unit: '38',
						second_unit: '100.4',
					},
					{
						id: 4,
						color: COLORS[3],
						icon: ICONS.advice,
						description: 'Seek advice from your doctor',
						first_unit: '38.1 - 39',
						second_unit: '100.58 - 102.2',
					},
					{
						id: 5,
						color: COLORS[4],
						icon: ICONS.urgent,
						description: 'Need urgent medical advice - call emergency',
						first_unit: '39 or more',
						second_unit: '102.2 or more',
					},
				];
				break;
			case 2:
				items = [
					{
						id: 1,
						color: COLORS[1],
						icon: ICONS.urgent,
						description: 'Normal Readings',
						first_unit: '96% or more',
						second_unit: '40 - 100',
					},
					{
						id: 2,
						color: COLORS[2],
						icon: ICONS.urgent,
						description: 'Acceptable to continue home monitoring',
						first_unit: '95%',
						second_unit: '101 - 109',
					},
					{
						id: 3,
						color: COLORS[3],
						icon: ICONS.urgent,
						description: 'Seek advice from your doctor',
						first_unit: '93 - 94%',
						second_unit: '110 - 130',
					},
					{
						id: 4,
						color: COLORS[4],
						icon: ICONS.urgent,
						description: 'Need urgent medical advice - call emergency',
						first_unit: '92% or less',
						second_unit: '131 or more',
					},
				];
				break;
			case 3:
				items = [
					{
						id: 1,
						color: COLORS[0],
						icon: ICONS.urgent,
						description: 'LOW**',
						classification: 'Low**',
						systolicBloodPressure: '< 90',
						diastolicBloodPressure: '< 60',
						case: 'or',
						pulseRate: '< 40',
					},
					{
						id: 2,
						color: COLORS[1],
						icon: ICONS.urgent,
						classification: 'Normal',
						systolicBloodPressure: '<120',
						diastolicBloodPressure: '<80',
						case: 'and',
						pulseRate: '40-100',
					},
					{
						id: 3,
						color: COLORS[2],
						icon: ICONS.urgent,
						classification: 'Prehypertension',
						systolicBloodPressure: '120 – 139',
						diastolicBloodPressure: '80 – 89',
						case: 'or',
						pulseRate: '101-109',
					},
					{
						id: 4,
						color: COLORS[3],
						icon: ICONS.urgent,
						classification: 'High: Stage One Hypertension',
						systolicBloodPressure: '140 – 159',
						diastolicBloodPressure: '90 – 99',
						case: 'or',
						pulseRate: '110-130',
					},
					{
						id: 5,
						color: COLORS[4],
						icon: ICONS.urgent,
						classification: 'High: Stage Two Hypertension',
						systolicBloodPressure: '≥160',
						diastolicBloodPressure: '≥100',
						case: 'or',
						pulseRate: '131 or more',
					},
				];
				break;
			case 6:
				items = [
					{
						id: 1,
						color: COLORS[5],
						icon: ICONS.urgent,
						description: 'Severe Hypoglycemia',
						first_unit: '< 53',
						second_unit: '< 3',
					},
					{
						id: 2,
						color: COLORS[0],
						icon: ICONS.urgent,
						description: 'Hypoglycemia',
						first_unit: '< 70',
						second_unit: '< 3.9',
					},
					{
						id: 3,
						color: COLORS[1],
						icon: ICONS.urgent,
						description: 'Normal',
						first_unit: '< 125',
						second_unit: '< 7',
					},
					{
						id: 4,
						color: COLORS[3],
						icon: ICONS.urgent,
						description: 'Hyperglycemia',
						first_unit: '< 200',
						second_unit: '< 10',
					},
					{
						id: 5,
						color: COLORS[4],
						icon: ICONS.urgent,
						description: 'Diabetes',
						first_unit: '> 200 - 500+',
						second_unit: '> 10 - 27.7+',
					},
				];
				break;
			case 7:
				items = [
					{
						id: 1,
						color: COLORS[5],
						icon: ICONS.urgent,
						description: 'Optimal',
						first_unit: '< 200',
						second_unit: '< 5.2',
					},
					{
						id: 2,
						color: COLORS[1],
						icon: ICONS.urgent,
						description: 'Intermediate',
						first_unit: '200 - 239',
						second_unit: '5.3 - 6.2',
					},
					{
						id: 3,
						color: COLORS[4],
						icon: ICONS.urgent,
						description: 'High',
						first_unit: '> 239',
						second_unit: '> 6.2',
					},
				];
				break;
			}
			return items;
		},
	},
};
</script>
