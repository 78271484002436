
<template>
  <div data-app>
    <v-container ref="content">
        <v-card class="elevation-0 my-n2" style="" width="100%">
            <v-card-title style="background-color: #fff; color: #575CE5;">
                <!-- <v-icon class="mx-6" color="#fff">{{ item.icon }}</v-icon> -->
                <v-img max-height="40px" max-width="40px" style="color: #575CE5" contain :src="item.img"></v-img>
                <span class="ml-4">{{ item.deviceName }}</span>
                <v-spacer />
                <v-icon v-if="item.hide" color="primary" @click="$emit('show')">mdi-chevron-up</v-icon>
                <v-icon v-if="!item.hide" color="primary" @click="$emit('hide')">mdi-chevron-down</v-icon>
            </v-card-title>
            <v-divider />
            <v-spacer></v-spacer>
            <div v-if="item.hide">
            <v-card-text>
                <v-row >
                    <v-col cols="12" lg="12">
                        <v-card v-if="item.deviceType === 1 && item.measurementsData.length !== 0" flat>
                            <v-card-text>
                                <v-row dense>
                                    <v-col cols="12" lg="7" md="7" sm="12">
                                        <v-row>
                                            <v-col>
                                                <h3 style="color: grey">{{ $t("last-measurements") }} </h3>
                                            </v-col>
                                            <v-col>
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ lastNormalTemperature }} <span v-html="minValues.unit === 'c' ? `${celsius}` : `${fahrenheit}`"></span></span>
                                            </v-col>
                                            <v-col cols="0" xl="4" lg="4" md="4">
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <h3 style="color: grey">{{ $t("min") }}: </h3>
                                            </v-col>
                                            <v-col>
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ minValues.value }} <span v-html="minValues.unit === 'c' ? `${celsius}` : `${fahrenheit}`"></span> - {{ minValues.date }}</span>
                                            </v-col>
                                            <v-col cols="0" xl="4" lg="4" md="4">
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <h3 style="color: grey">{{ $t("max") }}: </h3>
                                            </v-col>
                                            <v-col>
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ maxValues.value }} <span v-html="minValues.unit === 'c' ? `${celsius}` : `${fahrenheit}`"></span> - {{ maxValues.date }}</span>
                                            </v-col>
                                            <v-col cols="0" xl="4" lg="4" md="4">
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" lg="5" md="5" sm="12">
                                        <v-row>
                                            <h3 class="mt-4 ml-2" style="color: grey">{{ $t("measurements-date") }}</h3>
                                            <v-menu left bottom :close-on-content-click="false">
                                                <template v-slot:activator="{ on }">
                                                    <div class="d-flex align-center">
                                                    <v-text-field
                                                        v-on="on"
                                                        v-model="dateRangeText"
                                                        class="mt-2 mx-2"
                                                        style="width: 50%;"
                                                        append-icon="mdi-filter"
                                                        outlined
                                                        dense
                                                        readonly
                                                    ></v-text-field>
                                                    </div>
                                                </template>
                                                <v-date-picker
                                                    v-model="dates"
                                                    range
                                                    no-title
                                                    style="z-index: 0; width: 100%;"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-row>
                                        <v-row>
                                            <v-radio-group class="ml-2" v-model="changeTempUnit" row>
                                                    <v-radio
                                                        :label="$t('temperature-in-celsius')"
                                                        value="1"
                                                    ></v-radio>
                                                    <v-radio
													:label="$t('temperature-in-fahrenheit')"
                                                        value="2"
                                                    ></v-radio>
                                                </v-radio-group>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card v-if="item.deviceType === 2 && item.measurementsData.length !== 0" flat>
                            <v-card-text>
                                <v-row dense>
                                    <v-col cols="12" lg="7" md="7" sm="12">
                                        <v-row>
                                            <v-col cols="12" xl="3" lg="3" md="3" sm="3">
                                                <h3 style="color: grey">{{ $t("last-measurements") }} </h3>
                                            </v-col>
                                            <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ lastNormalBPM }} (bpm)</span>
                                            </v-col>
                                            <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ lastNormalSpo2 }} (%)</span>
                                            </v-col>
                                            <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ lastNormalPI }} (PI)</span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" xl="3" lg="3" md="3" sm="3">
                                                <h3 style="color: grey">{{ $t("min") }}: </h3>
                                            </v-col>
                                            <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ minValues.value }} (bpm) - {{ minValues.date }} </span>
                                            </v-col>
                                            <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ minValues.secondValue }} (%) - {{ minValues.secondValueDate }} </span>
                                            </v-col>
                                            <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ minValues.thirdValue }} (PI) - {{ minValues.thirdValueDate }} </span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" xl="3" lg="3" md="3" sm="3">
                                                <h3 style="color: grey">{{ $t("max") }}: </h3>
                                            </v-col>
                                            <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ maxValues.value }} (bpm) - {{ maxValues.date }}</span>
                                            </v-col>
                                            <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ maxValues.secondValue }} (%) - {{ maxValues.secondValueDate }} </span>
                                            </v-col>
                                            <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ maxValues.thirdValue }} (PI) - {{ maxValues.thirdValueDate }} </span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" lg="5" md="5" sm="12">
                                        <v-row>
                                            <h3 class="mt-4 ml-2" style="color: grey">{{ $t("measurements-date") }}</h3>
                                            <v-menu left bottom :close-on-content-click="false">
                                                <template v-slot:activator="{ on }">
                                                    <div class="d-flex align-center">
                                                    <v-text-field
                                                        v-on="on"
                                                        v-model="dateRangeText"
                                                        class="mt-2 mx-2"
                                                        style="width: 50%;"
                                                        append-icon="mdi-filter"
                                                        outlined
                                                        dense
                                                        readonly
                                                    ></v-text-field>
                                                    </div>
                                                </template>
                                                <v-date-picker
                                                    v-model="dates"
                                                    range
                                                    no-title
                                                    style="z-index: 0; width: 100%;"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card v-if="item.deviceType === 3 && item.measurementsData.length !== 0" flat>
                            <v-card-text>
                                <v-row dense>
                                    <v-col cols="12" lg="7" md="7" sm="12">
                                        <v-row>
                                            <v-col cols="12" xl="3" lg="3" md="3" sm="3">
                                                <h3 style="color: grey">{{ $t("last-measurements") }} </h3>
                                            </v-col>
                                            <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ lastNormalSystolic }} (mmHg)</span>
                                            </v-col>
                                            <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ lastNormalDiastolic }} (mmHg)</span>
                                            </v-col >
                                            <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ lastNormalPulse }} bpm</span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" xl="3" lg="3" md="3" sm="3">
                                                <h3 style="color: grey">{{ $t("min") }}: </h3>
                                            </v-col>
                                            <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ minValues.value }} (mmHg) - {{ minValues.date }} </span>
                                            </v-col>
                                            <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ minValues.secondValue }} (mmHg) - {{ minValues.secondValueDate }} </span>
                                            </v-col>
                                            <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ minValues.thirdValue }} (mmHg) - {{ minValues.thirdValueDate }} </span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" xl="3" lg="3" md="3" sm="3">
                                                <h3 style="color: grey">{{ $t("max") }}: </h3>
                                            </v-col>
                                            <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ maxValues.value }} (mmHg) - {{ maxValues.date }}</span>
                                            </v-col>
                                            <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ maxValues.secondValue }} (mmHg) - {{ maxValues.secondValueDate }}</span>
                                            </v-col>
                                            <v-col cols="4" xl="3" lg="3" md="3" sm="3">
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ maxValues.thirdValue }} (mmHg) - {{ maxValues.thirdValueDate }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" lg="5" md="5" sm="12">
                                        <v-row>
                                            <h3 class="mt-4 ml-2" style="color: grey">{{ $t("measurements-date") }}</h3>
                                            <v-menu left bottom :close-on-content-click="false">
                                                <template v-slot:activator="{ on }">
                                                    <div class="d-flex align-center">
                                                    <v-text-field
                                                        v-on="on"
                                                        v-model="dateRangeText"
                                                        class="mt-2 mx-2"
                                                        style="width: 50%;"
                                                        append-icon="mdi-filter"
                                                        outlined
                                                        dense
                                                        readonly
                                                    ></v-text-field>
                                                    </div>
                                                </template>
                                                <v-date-picker
                                                    v-model="dates"
                                                    range
                                                    no-title
                                                    style="z-index: 0; width: 100%;"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card v-if="item.deviceType === 5 && item.measurementsData.length !== 0" flat>
                            <v-card-text>
                                <v-row dense>
                                    <v-col cols="12" lg="7" md="7" sm="12">
                                        <v-row>
                                            <v-col>
                                                <h3 style="color: grey">{{ $t("last-measurements") }} </h3>
                                            </v-col>
                                            <v-col>
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ lastNormalWeight }} {{ minValues.unit }}</span>
                                            </v-col>
                                            <v-col cols="0" xl="4" lg="4" md="4">
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <h3 style="color: grey">{{ $t("min") }}: </h3>
                                            </v-col>
                                            <v-col>
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ minValues.value }} {{ minValues.unit }} - {{ minValues.date }}</span>
                                            </v-col>
                                            <v-col cols="0" xl="4" lg="4" md="4">
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <h3 style="color: grey">{{ $t("max") }}: </h3>
                                            </v-col>
                                            <v-col>
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ maxValues.value }} {{ minValues.unit }} - {{ maxValues.date }}</span>
                                            </v-col>
                                            <v-col cols="0" xl="4" lg="4" md="4">
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" lg="5" md="5" sm="12">
                                        <v-row>
                                            <h3 class="mt-4 ml-2" style="color: grey">{{ $t("measurements-date") }}</h3>
                                            <v-menu left bottom :close-on-content-click="false">
                                                <template v-slot:activator="{ on }">
                                                    <div class="d-flex align-center">
                                                    <v-text-field
                                                        v-on="on"
                                                        v-model="dateRangeText"
                                                        class="mt-2 mx-2"
                                                        style="width: 50%;"
                                                        append-icon="mdi-filter"
                                                        outlined
                                                        dense
                                                        readonly
                                                    ></v-text-field>
                                                    </div>
                                                </template>
                                                <v-date-picker
                                                    v-model="dates"
                                                    range
                                                    no-title
                                                    style="z-index: 0; width: 100%;"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-row>
                                        <v-row>
                                            <v-radio-group class="ml-2" v-model="changeScaleUnit" row>
                                                    <v-radio
                                                        label="Weight (kg)"
                                                        value="1"
                                                    ></v-radio>
                                                    <v-radio
                                                        label="Weight (lbs)"
                                                        value="2"
                                                    ></v-radio>
                                                </v-radio-group>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card v-if="item.deviceType === 6 && item.measurementsData.length !== 0" flat>
                            <v-card-text>
                                <v-row dense>
                                    <v-col cols="12" lg="7" md="7" sm="12">
                                        <v-row>
                                            <v-col>
                                                <h3 style="color: grey">{{ $t("last-measurements") }} </h3>
                                            </v-col>
                                            <v-col>
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ lastNormalGlucose }} {{ minValues.unit }}</span>
                                            </v-col>
                                            <v-col cols="0" xl="4" lg="4" md="4">
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="4">
                                                <h3 style="color: grey">{{ $t("min") }}: </h3>
                                            </v-col>
                                            <v-col>
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ minValues.value }} {{ minValues.unit }} - {{ minValues.date }}</span>
                                            </v-col>
                                            <v-col cols="0" xl="4" lg="4" md="4">
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="4">
                                                <h3 style="color: grey">{{ $t("max") }}: </h3>
                                            </v-col>
                                            <v-col>
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ maxValues.value }} {{ minValues.unit }} - {{ maxValues.date }}</span>
                                            </v-col>
                                            <v-col cols="0" xl="4" lg="4" md="4">
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" lg="5" md="5" sm="12">
                                        <v-row>
                                            <h3 class="mt-4 ml-2" style="color: grey">{{ $t("measurements-date") }}</h3>
                                            <v-menu left bottom :close-on-content-click="false">
                                                <template v-slot:activator="{ on }">
                                                    <div class="d-flex align-center">
                                                    <v-text-field
                                                        v-on="on"
                                                        v-model="dateRangeText"
                                                        class="mt-2 mx-2"
                                                        style="width: 50%;"
                                                        append-icon="mdi-filter"
                                                        outlined
                                                        dense
                                                        readonly
                                                    ></v-text-field>
                                                    </div>
                                                </template>
                                                <v-date-picker
                                                    v-model="dates"
                                                    range
                                                    no-title
                                                    style="z-index: 0; width: 100%;"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-row>
                                        <v-row>
                                            <v-radio-group class="ml-2" v-model="changeGlucoseUnit" row>
                                                <v-radio
												:label="$t('glucose-mg-dL')"
                                                    value="1"
                                                ></v-radio>
                                                <v-radio
												:label="$t('glucose-mmol-L')"
                                                    value="2"
                                                ></v-radio>
                                            </v-radio-group>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card v-if="item.deviceType === 7 && item.measurementsData.length !== 0" flat>
                            <v-card-text>
                                <v-row dense>
                                    <v-col cols="12" lg="7" md="7" sm="12">
                                      <v-row>
                                            <v-col>
                                                <h3 style="color: grey">{{ $t("last-measurements") }} </h3>
                                            </v-col>
                                            <v-col>
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ lastNormalCholesterol }} {{ minValues.unit }}</span>
                                            </v-col>
                                            <v-col cols="0" xl="4" lg="4" md="4">
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="4">
                                                <h3 style="color: grey">{{ $t("min") }}: </h3>
                                            </v-col>
                                            <v-col>
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ minValues.value }} {{ minValues.unit }} - {{ minValues.date }}</span>
                                            </v-col>
                                            <v-col cols="0" xl="4" lg="4" md="4">
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="4">
                                                <h3 style="color: grey">{{ $t("max") }}: </h3>
                                            </v-col>
                                            <v-col>
                                                <span style="font-weight: 500; font-size: 14px; color: #575CE5">{{ maxValues.value }} {{ minValues.unit }} - {{ maxValues.date }}</span>
                                            </v-col>
                                            <v-col cols="0" xl="4" lg="4" md="4">
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" lg="5" md="5" sm="12">
                                        <v-row>
                                            <h3 class="mt-4 ml-2" style="color: grey">{{ $t("measurements-date") }}</h3>
                                            <v-menu left bottom :close-on-content-click="false">
                                                <template v-slot:activator="{ on }">
                                                    <div class="d-flex align-center">
                                                    <v-text-field
                                                        v-on="on"
                                                        v-model="dateRangeText"
                                                        class="mt-2 mx-2"
                                                        style="width: 50%;"
                                                        append-icon="mdi-filter"
                                                        outlined
                                                        dense
                                                        readonly
                                                    ></v-text-field>
                                                    </div>
                                                </template>
                                                <v-date-picker
                                                    v-model="dates"
                                                    range
                                                    no-title
                                                    style="z-index: 0; width: 100%;"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-row>
                                        <v-row>
                                            <v-radio-group class="ml-2" v-model="changeCholesterolUnit" row>
                                                <v-radio
                                                    label="Cholesterol (mg/dL)"
                                                    value="1"
                                                ></v-radio>
                                                <v-radio
                                                    label="Cholesterol (mmol/L)"
                                                    value="2"
                                                ></v-radio>
                                            </v-radio-group>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <v-divider v-if="item.measurementsData.length !== 0"></v-divider>
                <!-- <LineChart v-if="item.measurementsData.length !== 0" style="z-index: 0;" :data="data" :labels="labels" :changeScaleUnit="changeScaleUnit" :changeTempUnit="changeTempUnit" :changeGlucoseUnit="changeGlucoseUnit" :changeCholesterolUnit="changeCholesterolUnit"/> -->
                <v-row v-if="item.measurementsData.length !== 0" style="margin-top: 15px;">
                  <v-col cols="12" :xl="deviceType !== 5 ? '4' : '12'" :lg="deviceType !== 5 ? '4' : '12'" :md="deviceType !== 5 ? '5' : '12'" sm="12">
                      <v-data-table
                          :headers="measurementsHeaders"
                          :items="measuredData"
					:no-data-text="$t('no-data-available')"
      :loading-text="$t('loading')"
                          :items-per-page="5"
                          :item-class="itemRowBackground"
                          :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
                      ></v-data-table>
                  </v-col>
                  <v-col v-if="deviceType !== 5" cols="12" xl="8" lg="8" md="7" sm="12">
                      <reference :item="item" />
                  </v-col>
              </v-row>
                <div v-if="item.measurementsData.length === 0" style="backgroundColor: transparent; padding: 12px; border: 1px dashed #E8E8E8; border-radius: 5px;">
                  <span class="primary--text pa-2" depressed>No data have been measured until now.</span>
                 </div>
            </v-card-text>
            </div>
        </v-card>
    </v-container>
  </div>
</template>
<script>
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import LineChart from './LineChart.vue';
import { formattedDateTime } from '@/utils/luxon-formater';
import Reference from './Reference.vue';

export default {
	components: {
		// LineChart,
		Reference,
	},
	data () {
		return {
			dates: [],
			changeTempUnit: '1',
			changeScaleUnit: '1',
			changeGlucoseUnit: '1',
			changeCholesterolUnit: '1',
			fahrenheit: '&deg;F',
			celsius: '&deg;C',
			timeLocalization: this.$i18n.locale,
		};
	},
	props: ['item'],
	computed: {
		dateRangeText () {
			return this.dates[0] + ' - ' + this.dates[1];
		},
		measurementsHeaders () {
			var headers = [];
			switch (this.item.deviceType) {
			case 1:
				headers = [
					{ text: 'Measured At', value: 'measuredAtFormatted' },
					{ text: 'Temperature in Celcius', value: 'Temperature_C' },
					{ text: 'Temperature in Fahrenheit', value: 'Temperature_F' },
				];
				break;
			case 2:
				headers = [
					{ text: 'Measured At', value: 'measuredAtFormatted' },
					{ text: 'BPM', value: 'BPM' },
					{ text: 'SpO2', value: 'Spo2' },
					{ text: 'Pulse Index', value: 'PI' },
				];
				break;
			case 3:
				headers = [
					{ text: 'Measured At', value: 'measuredAtFormatted' },
					{ text: 'Systolic', value: 'Sys' },
					{ text: 'Diastolic', value: 'Dias' },
					{ text: 'Pulse', value: 'Pul' },
				];
				break;
			case 5:
				headers = [
					{ text: 'Measured At', value: 'measuredAtFormatted' },
					{ text: 'Weight (kg)', value: 'weight_kg' },
					{ text: 'Weight (lbs))', value: 'weight_lbs' },
				];
				break;
			case 6:
				headers = [
					{ text: 'Measured At', value: 'measuredAtFormatted' },
					{ text: 'Glucose (mg/dL)', value: 'glucose_mgL' },
					{ text: 'Glucose (mmol/L)', value: 'glucose_mmolL' },
				];
				break;
			case 7:
				headers = [
					{ text: 'Measured At', value: 'measuredAtFormatted' },
					{ text: 'Cholesterol (mg/dL)', value: 'cholesterol_mgL' },
					{ text: 'Cholesterol (mmol/L)', value: 'cholesterol_mmolL' },
				];
				break;
			}
			return headers;
		},
		measurements () {
			return this.item.measurementsData;
		},
		deviceType () {
			return this.item.deviceType;
		},
		measuredData () {
			const values = [];
			if (this.measurements.length > 0) {
				this.measurements.forEach((data) => {
					const val = JSON.parse(data.measuredDataJson);
					val.deviceType = data.deviceType;
					val.measuredAt = data.measuredAt;
					val.measuredDate = formattedDateTime(data.measuredAt, 'date', this.timeLocalization, null);
					val.measuredAtFormatted = formattedDateTime(data.measuredAt, 'datetime_short', this.timeLocalization, null);
					val.recordedAt = data.recordedAt;
					values.push(val);
				});
			}
			return values;
		},
		lastNormalBPM () {
			return this.measuredData[0]?.BPM;
		},
		lastNormalSpo2 () {
			return this.measuredData[0]?.Spo2;
		},
		lastNormalPI () {
			return this.measuredData[0]?.PI;
		},
		lastNormalTemperature () {
			if (parseInt(this.changeTempUnit) === 1) {
				return `${this.measuredData[0]?.Temperature_C}`;
			} else return this.measuredData[0]?.Temperature_F;
		},
		lastNormalTemperature_F () {
			return this.measuredData[0]?.Temperature_F;
		},
		lastNormalWeight () {
			if (parseInt(this.changeScaleUnit) === 1) {
				return this.measuredData[0]?.weight_kg;
			} else return this.measuredData[0]?.weight_lbs;
		},
		lastNormalGlucose () {
			if (parseInt(this.changeGlucoseUnit) === 1) {
				return this.measuredData[0]?.glucose_mgL;
			} else return this.measuredData[0]?.glucose_mmolL;
		},
		lastNormalCholesterol () {
			if (parseInt(this.changeCholesterolUnit) === 1) {
				return this.measuredData[0]?.cholesterol_mgL;
			} else return this.measuredData[0]?.cholesterol_mmolL;
		},
		lastNormalSystolic () {
			return this.measuredData[0]?.Sys;
		},
		lastNormalDiastolic () {
			return this.measuredData[0]?.Dias;
		},
		lastNormalPulse () {
			return this.measuredData[0]?.Pul;
		},
		lastMeasureDate () {
			return this.measuredData[0]?.measuredAt.split('T')[0];
		},
		firstMeasureDate () {
			if (this.measuredData.length > 0) {
				const length = this.measuredData.length - 1;
				return this.measuredData[length].measuredAt.split('T')[0];
			} else return 0;
		},
		valuesDiastolic () {
			const values = [];
			this.measuredData.filter((c) => {
				values.push(c.Dias);
			});
			return values.reverse();
		},
		valuesSystolic () {
			const values = [];
			this.measuredData.filter((c) => {
				values.push(c.Sys);
			});
			return values.reverse();
		},
		valuesPulse () {
			const values = [];
			this.measuredData.filter((c) => {
				values.push(c.Pul);
			});
			return values.reverse();
		},
		valuesGlucoseMgL () {
			const values = [];
			this.measuredData.filter((c) => {
				values.push(c.glucose_mgL);
			});
			return values.reverse();
		},
		valuesGlucoseMmolL () {
			const values = [];
			this.measuredData.filter((c) => {
				values.push(c.glucose_mmolL);
			});
			return values.reverse();
		},
		valuesCholesterolMgL () {
			const values = [];
			this.measuredData.filter((c) => {
				values.push(c.cholesterol_mgL);
			});
			return values.reverse();
		},
		valuesCholesterolMmolL () {
			const values = [];
			this.measuredData.filter((c) => {
				values.push(c.cholesterol_mmolL);
			});
			return values.reverse();
		},
		valuesWeightKg () {
			const values = [];
			this.measuredData.filter((c) => {
				values.push(c.weight_kg);
			});
			return values.reverse();
		},
		valuesWeightLbs () {
			const values = [];
			this.measuredData.filter((c) => {
				values.push(c.weight_lbs);
			});
			return values.reverse();
		},
		valuesTemperature_C () {
			const values = [];
			this.measuredData.filter((c) => {
				values.push(c.Temperature_C);
			});
			return values.reverse();
		},
		valuesTemperature_F () {
			const values = [];
			this.measuredData.filter((c) => {
				values.push(c.Temperature_F);
			});
			return values.reverse();
		},
		valuesBPM () {
			const values = [];
			this.measuredData.filter((c) => {
				values.push(c.BPM);
			});
			return values.reverse();
		},
		valuesSpO2 () {
			const values = [];
			this.measuredData.filter((c) => {
				values.push(c.Spo2);
			});
			return values.reverse();
		},
		valuesPI () {
			const values = [];
			this.measuredData.filter((c) => {
				values.push(c.PI);
			});
			return values.reverse();
		},
		labels () {
			var labels = [];
			var dns = [];
			this.measuredData.filter((c) => {
				labels.push(c.measuredAt.split('T')[0]);
			});
			labels.forEach((data) => {
				if (this.dates[0] !== undefined && this.dates[1] !== undefined) {
					var a = new Date(
						this.dates[0].split('-')[0],
						this.dates[0].split('-')[1] - 1,
						this.dates[0].split('-')[2],
					);
					var b = new Date(
						this.dates[1].split('-')[0],
						this.dates[1].split('-')[1] - 1,
						this.dates[1].split('-')[2],
					);
					var dt = new Date(
						data.split('-')[0],
						data.split('-')[1] - 1,
						data.split('-')[2],
					);
					var inRange = dt >= a && dt <= b;
					if (inRange) {
						dns.push(data);
					}
				}
			});
			return dns.reverse();
			// return dns
		},
		testData () {
			return this.measuredData;
		},
		data () {
			var data = [];
			switch (this.item.deviceType) {
			case 1:
				if (parseInt(this.changeTempUnit) === 1) {
					data = [
						{
							label: 'Temperature in Celcius',
							data: this.valuesTemperature_C,
							backgroundColor: 'transparent',
							borderColor: '#3949AB',
							pointBackgroundColor: 'rgba(171, 71, 188, 1)',
						},
					];
				} else {
					data = [
						{
							label: 'Temperature in Fahrenheit',
							data: this.valuesTemperature_F,
							backgroundColor: 'transparent',
							borderColor: '#8E24AA',
							pointBackgroundColor: 'rgba(171, 71, 188, 1)',
						},
					];
				}
				break;
			case 2:
				data = [
					{
						label: 'BPM',
						data: this.valuesBPM,
						backgroundColor: 'transparent',
						borderColor: '#3949AB',
						pointBackgroundColor: 'rgba(171, 71, 188, 1)',
					},
					{
						label: 'SpO2',
						data: this.valuesSpO2,
						backgroundColor: 'transparent',
						borderColor: '#B388FF',
						pointBackgroundColor: 'rgba(171, 71, 188, 1)',
					},
					{
						label: 'PI',
						data: this.valuesPI,
						backgroundColor: 'transparent',
						borderColor: '#8E24AA',
						pointBackgroundColor: 'rgba(171, 71, 188, 1)',
					},
				];
				break;
			case 3:
				data = [
					{
						label: 'Systolic',
						data: this.valuesSystolic,
						backgroundColor: 'transparent',
						borderColor: '#3949AB',
						pointBackgroundColor: 'rgba(171, 71, 188, 1)',
					},
					{
						label: 'Diastolic',
						data: this.valuesDiastolic,
						backgroundColor: 'transparent',
						borderColor: '#B388FF',
						pointBackgroundColor: 'rgba(171, 71, 188, 1)',
					},
					{
						label: 'Pulse',
						data: this.valuesPulse,
						backgroundColor: 'transparent',
						borderColor: '#8E24AA',
						pointBackgroundColor: 'rgba(171, 71, 188, 1)',
					},
				];
				break;
			case 5:
				if (parseInt(this.changeScaleUnit) === 1) {
					data = [
						{
							label: 'Weight (kg)',
							data: this.valuesWeightKg,
							backgroundColor: 'transparent',
							borderColor: '#3949AB',
							pointBackgroundColor: 'rgba(171, 71, 188, 1)',
						},
					];
				} else {
					data = [
						{
							label: 'Weight (lbs)',
							data: this.valuesWeightLbs,
							backgroundColor: 'transparent',
							borderColor: '#8E24AA',
							pointBackgroundColor: 'rgba(171, 71, 188, 1)',
						},
					];
				}
				break;
			case 6:
				if (parseInt(this.changeGlucoseUnit) === 1) {
					data = [
						{
							label: 'Glucose(mg/dL)',
							data: this.valuesGlucoseMgL,
							backgroundColor: 'transparent',
							borderColor: '#3949AB',
							pointBackgroundColor: 'rgba(171, 71, 188, 1)',
						},
					];
				} else {
					data = [
						{
							label: 'Glucose(mmol/L)',
							data: this.valuesGlucoseMmolL,
							backgroundColor: 'transparent',
							borderColor: '#8E24AA',
							pointBackgroundColor: 'rgba(171, 71, 188, 1)',
						},
					];
				}
				break;
			case 7:
				if (parseInt(this.changeCholesterolUnit) === 1) {
					data = [
						{
							label: 'Cholesterol(mg/dL)',
							data: this.valuesCholesterolMgL,
							backgroundColor: 'transparent',
							borderColor: '#3949AB',
							pointBackgroundColor: 'rgba(171, 71, 188, 1)',
						},
					];
				} else {
					data = [
						{
							label: 'Cholesterol(mmol/L)',
							data: this.valuesCholesterolMmolL,
							backgroundColor: 'transparent',
							borderColor: '#8E24AA',
							pointBackgroundColor: 'rgba(171, 71, 188, 1)',
						},
					];
				}
				break;
			}
			return data;
		},
		minValues () {
			const temp_array = [];
			this.measuredData.filter((c) => {
				switch (c.device_type) {
				case '1':
					temp_array.push({
						value:
                this.changeTempUnit === '1' ? c.Temperature_C : c.Temperature_F,
						dev: c.device_type,
						date: c.measuredAtFormatted,
						unit: this.changeTempUnit === '1' ? 'c' : 'f',
					});
					break;
				case '2':
					temp_array.push({
						value: c.BPM,
						dev: c.device_type,
						date: c.measuredAtFormatted,
						secondValue: c.Spo2,
						thirdValue: c.PI,
					});
					break;
				case '3':
					temp_array.push({
						value: c.Dias,
						dev: c.device_type,
						date: c.measuredAtFormatted,
						secondValue: c.Sys,
						thirdValue: c.Pul,
					});
					break;
				case '5':
					temp_array.push({
						value: this.changeScaleUnit === '1' ? c.weight_kg : c.weight_lbs,
						dev: c.device_type,
						date: c.measuredAtFormatted,
						unit: this.changeScaleUnit === '1' ? 'kg' : 'lbs',
					});
					break;
				case '6':
					temp_array.push({
						value: this.changeGlucoseUnit === '1' ? c.glucose_mgL : c.glucose_mmolL,
						dev: c.device_type,
						date: c.measuredAtFormatted,
						unit: this.changeGlucoseUnit === '1' ? '(mg/dL)' : '(mmol/L)',
					});
					break;
				case '7':
					temp_array.push({
						value: this.changeCholesterolUnit === '1' ? c.cholesterol_mgL : c.cholesterol_mmolL,
						dev: c.device_type,
						date: c.measuredAtFormatted,
						unit: this.changeCholesterolUnit === '1' ? '(mg/dL)' : '(mmol/L)',
					});
					break;
				}
			});
			if (this.deviceType === 3) {
				var min_found = Math.min.apply(
					Math,
					temp_array.map(function (o) {
						return o.value;
					}),
				);
				var min_found_second_value = Math.min.apply(
					Math,
					temp_array.map(function (o) {
						return o.secondValue;
					}),
				);
				var min_found_third_value = Math.min.apply(
					Math,
					temp_array.map(function (o) {
						return o.thirdValue;
					}),
				);
				var second_value_date = this.measuredData.filter((c) => {
					return c.Sys === min_found_second_value;
				});
				var third_value_date = this.measuredData.filter((c) => {
					return c.Pul === min_found_third_value;
				});
				var returned_object = temp_array.filter((c) => c.value === min_found);
				returned_object[0].secondValue = min_found_second_value;
				returned_object[0].thirdValue = min_found_third_value;
				returned_object[0].secondValueDate =
          second_value_date[0].measuredAtFormatted;
				returned_object[0].thirdValueDate =
          third_value_date[0].measuredAtFormatted;
				return returned_object[0];
			} else if (this.deviceType === 2) {
				min_found = Math.min.apply(
					Math,
					temp_array.map(function (o) {
						return o.value;
					}),
				);
				min_found_second_value = Math.min.apply(
					Math,
					temp_array.map(function (o) {
						return o.secondValue;
					}),
				);
				min_found_third_value = Math.min.apply(
					Math,
					temp_array.map(function (o) {
						return o.thirdValue;
					}),
				);
				second_value_date = this.measuredData.filter((c) => {
					return c.Spo2 === min_found_second_value;
				});
				third_value_date = this.measuredData.filter((c) => {
					return c.PI === min_found_third_value;
				});
				returned_object = temp_array.filter((c) => c.value === min_found);
				returned_object[0].secondValue = min_found_second_value;
				returned_object[0].thirdValue = min_found_third_value;
				returned_object[0].secondValueDate =
          second_value_date[0].measuredAtFormatted;
				returned_object[0].thirdValueDate =
          third_value_date[0].measuredAtFormatted;
				return returned_object[0];
			} else {
				min_found = Math.min.apply(
					Math,
					temp_array.map(function (o) {
						return o.value;
					}),
				);
				returned_object = temp_array.filter((c) => c.value === min_found);
			}
			return returned_object[0];
		},
		maxValues () {
			const temp_array = [];
			this.measuredData.filter((c) => {
				switch (c.device_type) {
				case '1':
					temp_array.push({
						value:
                this.changeTempUnit === '1' ? c.Temperature_C : c.Temperature_F,
						dev: c.device_type,
						date: c.measuredAtFormatted,
						unit: this.changeTempUnit === '1' ? 'c' : 'f',
					});
					break;
				case '2':
					temp_array.push({
						value: c.BPM,
						dev: c.device_type,
						date: c.measuredAtFormatted,
						secondValue: c.Spo2,
						thirdValue: c.PI,
					});
					break;
				case '3':
					temp_array.push({
						value: c.Dias,
						dev: c.device_type,
						date: c.measuredAtFormatted,
						secondValue: c.Sys,
						thirdValue: c.Pul,
					});
					break;
				case '5':
					temp_array.push({
						value: this.changeScaleUnit === '1' ? c.weight_kg : c.weight_lbs,
						dev: c.device_type,
						date: c.measuredAtFormatted,
						unit: this.changeScaleUnit === '1' ? 'kg' : 'lbs',
					});
					break;
				case '6':
					temp_array.push({
						value: this.changeGlucoseUnit === '1' ? c.glucose_mgL : c.glucose_mmolL,
						dev: c.device_type,
						date: c.measuredAtFormatted,
						unit: this.changeGlucoseUnit === '1' ? '(mg/dL)' : '(mmol/L)',
					});
					break;
				case '7':
					temp_array.push({
						value: this.changeCholesterolUnit === '1' ? c.cholesterol_mgL : c.cholesterol_mmolL,
						dev: c.device_type,
						date: c.measuredAtFormatted,
						unit: this.changeCholesterolUnit === '1' ? '(mg/dL)' : '(mmol/L)',
					});
					break;
				}
			});
			if (this.deviceType === 3) {
				var max_found = Math.max.apply(
					Math,
					temp_array.map(function (o) {
						return o.value;
					}),
				);
				var max_found_second_value = Math.max.apply(
					Math,
					temp_array.map(function (o) {
						return o.secondValue;
					}),
				);
				var max_found_third_value = Math.max.apply(
					Math,
					temp_array.map(function (o) {
						return o.thirdValue;
					}),
				);
				var second_value_date = this.measuredData.filter((c) => {
					return c.Sys === max_found_second_value;
				});
				var third_value_date = this.measuredData.filter((c) => {
					return c.Pul === max_found_third_value;
				});
				var returned_object = temp_array.filter((c) => c.value === max_found);
				returned_object[0].secondValue = max_found_second_value;
				returned_object[0].thirdValue = max_found_third_value;
				returned_object[0].secondValueDate =
          second_value_date[0].measuredAtFormatted;
				returned_object[0].thirdValueDate =
          third_value_date[0].measuredAtFormatted;
				return returned_object[0];
			} else if (this.deviceType === 2) {
				max_found = Math.max.apply(
					Math,
					temp_array.map(function (o) {
						return o.value;
					}),
				);
				max_found_second_value = Math.max.apply(
					Math,
					temp_array.map(function (o) {
						return o.secondValue;
					}),
				);
				max_found_third_value = Math.max.apply(
					Math,
					temp_array.map(function (o) {
						return o.thirdValue;
					}),
				);
				second_value_date = this.measuredData.filter((c) => {
					return c.Spo2 === max_found_second_value;
				});
				third_value_date = this.measuredData.filter((c) => {
					return c.PI === max_found_third_value;
				});
				returned_object = temp_array.filter((c) => c.value === max_found);
				returned_object[0].secondValue = max_found_second_value;
				returned_object[0].thirdValue = max_found_third_value;
				returned_object[0].secondValueDate =
          second_value_date[0].measuredAtFormatted;
				returned_object[0].thirdValueDate =
          third_value_date[0].measuredAtFormatted;
				return returned_object[0];
			} else {
				max_found = Math.max.apply(
					Math,
					temp_array.map(function (o) {
						return o.value;
					}),
				);
				returned_object = temp_array.filter((c) => c.value === max_found);
			}
			return returned_object[0];
		},
	},
	mounted () {
		this.dates[0] = this.firstMeasureDate;
		this.dates[1] = this.lastMeasureDate;
	},
	methods: {
		itemRowBackground: function () {
			return 'style-dtable';
		},
	},
};
</script>
<style>
.style-dtable {
  font-size: 14px !important;
  font-weight: bold;
  color: #575ce5 !important;
}
.v-input--selection-controls {
  margin-left: 60px !important;
  margin-top: 5px !important;
  padding-top: 4px;
}
</style>
